
import { defineComponent, reactive, toRefs } from 'vue'
import { getTableConfig } from './config'
import CTable from '@/components/c-table'
import dialogRole from './components/dialog-role.vue'
import { getRoleList } from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils/index'

export default defineComponent({
  components: {
    CTable,
    dialogRole
  },
  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })
    const state = reactive({
      visible: false,
      rowData: {},
      dialogTitle: '新增角色'
    })
    const getList = async() => {
      const p = {
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      const [err, res] = await toResult(getRoleList(p))
      if (err) return false
      tableConfig.data = res.list
      tableConfig.pagination.total = res.total
      console.log(res)
    }
    /**
     * @description: 弹窗更新事件
     * @param {*} status
     * @return {*}
     */
    const handleRefresh = () => {
      getList()
    }
    const enterpriseTarget = (type, params?) => {
      state.visible = true
      if (type === 'enterprise-role-edit') {
        state.dialogTitle = '编辑角色'
        state.rowData = params
      } else {
        state.dialogTitle = '新增角色'
      }
      console.log(type, params)
    }
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getList()
    }
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getList()
    }
    getList()
    return {
      enterpriseTarget,
      btnName,
      handleRefresh,
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      ...toRefs(state)
    }
  }
})
