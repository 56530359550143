
import { defineComponent, reactive, ref, toRefs, watch, nextTick } from 'vue'
// import { treeData } from '@/utils/index'
import { toResult } from '@/utils/toResult'
import { getRolePermissionList } from '@/api/common'
import { getRoleAdd, getRoleDetail, getRoleEdit } from '../api'
import { ElMessage } from 'element-plus'
const valid = (msg = '必填') => {
  return { required: true, message: msg, trigger: ['blur', 'change'] }
}
export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object,
    title: String
  },
  setup(props, { emit }) {
    const data = reactive({
      formRef: ref<HTMLElement | null>(null),
      formData: ref<any>({
        roleType: 2,
        roleStatus: 1,
        treeList: []
      }),
      treeProps: {
        label: 'title',
        value: 'name'
      },
      isDialog: false,
      tree: null,
      treeList: [],
      keys: []
    })
    const prop = props
    const options = ref([])
    const rules = {
      roleName: [valid()],
      roleType: [valid()],
      roleStatus: [{ required: true, message: '必填', trigger: 'change' }],
      remark: []
      // treeList: [{ type: 'array', required: true, message: '必填', trigger: 'blur' }]
    }
    const setChecked = () => {
      // 修改设置勾选
      nextTick(() => {
        const permissions = data.formData.permissions
          ? JSON.parse(data.formData.permissions)
          : []

        data.tree.setCheckedKeys(permissions)
      })
    }
    const getLimits = () => {
      // 获取功能权限数据
      getRolePermissionList({
        platformCode: 2
      }).then(res => {
        // const arr = []
        data.treeList = res.data
        // .forEach(item => {
        //     arr.push({
        //       type: item.type,
        //       id: item.labelId,
        //       label: item.labelName,
        //       parentId: item.parentId
        //     })
        //   })
        //   console.log(treeData(arr), 'arr')
        //  = treeData(arr)
        console.log(data.treeList)
      })
    }

    // 关闭弹框
    const handleCloseDialog = () => {
      (data.formRef as any).resetFields()
      data.tree.setCheckedKeys([])
      emit('update:visible', false)
    }
    const handleRefresh = () => {
      emit('refresh-dialog')
    }
    const getDetail = async() => {
      const p = {
        roleId: props.rowData.roleId
      }
      const [err, res, msg] = await toResult(getRoleDetail(p))
      if (err) {
        ElMessage.warning(msg)
        return false
      }
      data.formData = res
      data.formData.treeList = JSON.parse(res.permissions)
      setChecked()
    }
    const handleAdd = async() => {
      console.log(data.formData.treeList, 'data.formData.treeList')
      const p = Object.assign(data.formData, {
        platformCode: 2,
        permissions: JSON.stringify(data.formData.treeList)
      })
      delete p.treeList
      console.log(data.formData)
      const [err, res, msg] = await toResult(getRoleAdd(p))
      if (err) {
        ElMessage.warning(msg)
        return false
      }
      handleCloseDialog()
      handleRefresh()
      ElMessage.success(msg)
      console.log(res)
    }
    const handleEdit = async() => {
      console.log(data.formData.treeList, 'data.formData.treeList')
      const p = Object.assign(data.formData, {
        platformCode: data.formData.platformCode,
        permissions: JSON.stringify(data.formData.treeList)
      })
      const [err, res, msg] = await toResult(getRoleEdit(p))
      if (err) {
        ElMessage.warning(msg)
        return false
      }
      handleCloseDialog()
      handleRefresh()
      ElMessage.success(msg)
      console.log(res)
    }
    // 保存提交数据
    const submitData = () => {
      const ref: any = data.formRef
      ref.validate((valid: boolean) => {
        if (!valid) return false
        prop.title !== '编辑角色' && handleAdd()
        prop.title === '编辑角色' && handleEdit()
      })
    }
    const handleTreeCheck = (node, nodes) => {
      data.formData.treeList = nodes.checkedNodes.filter(item => {
        if (item.type === 2) {
          return item.name
        }
      }).map(item => item.name)
    }
    watch(() => props.visible, (n) => {
      if (n) {
        getLimits()
        prop.title === '编辑角色' && getDetail()
      }
    })
    // watch(() => props.rowData, (n) => {
    //   console.log(n)
    // })
    // onMounted(() => {
    //   data.treeList.length === 0 &&
    // })

    return {
      ...toRefs(data),
      options,
      prop,
      rules,
      handleCloseDialog,
      handleTreeCheck,
      submitData
    }
  }
})
