/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-03-01 13:33:36
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-02 13:40:31
 */
import service from '@/utils/request'

// 管理员权限详情 tree数据
export const detailOfAccountPermission = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/detailOfAccountPermission',
    data: params
  })
// 权限
export const limitsFlattened = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/account/limitsFlattened',
    data: params
  })
// 新增预置角色
export const getRoleAdd = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/preset/role/add',
    data: params
  })
// 修改预置角色
export const getRoleEdit = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/update',
    data: params
  })
// 获取预置角色列表
export const getRoleList = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/page',
    data: params
  })
// 获取预置角色详情
export const getRoleDetail = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/detail',
    data: params
  })
