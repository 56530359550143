import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-bg" }
const _hoisted_2 = { style: {"margin-bottom":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_dialogRole = _resolveComponent("dialogRole")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.enterpriseTarget('enterprise-role-add'))),
        icon: "el-icon-folder-add"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnName('enterprise-role-add') || '新建前台角色'), 1)
        ]),
        _: 1
      }, 512), [
        [_directive_hasAuth, 'enterprise-role-add']
      ])
    ]),
    _createVNode(_component_c_table, {
      dataSource: _ctx.tableConfig,
      isIndex: true,
      onPageCurrentChange: _ctx.pageCurrentChange,
      onPageSizeChange: _ctx.pageSizeChange
    }, {
      action: _withCtx((scope) => [
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.enterpriseTarget('enterprise-role-edit', scope.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('enterprise-role-edit') || '编辑权限'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'enterprise-role-edit']
        ])
      ]),
      _: 1
    }, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"]),
    _createVNode(_component_dialogRole, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = $event)),
      rowData: _ctx.rowData,
      title: _ctx.dialogTitle,
      onRefreshDialog: _ctx.handleRefresh
    }, null, 8, ["visible", "rowData", "title", "onRefreshDialog"])
  ]))
}