/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-02-28 16:40:20
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-06 14:33:00
 */

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    // table 的高度
    height: null,

    isIndex: true,
    // table 索引 label
    indexLabel: '序号',

    defaultExpandAll: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'roleName',
      label: '角色名称',
      width: '140'
    },
    {
      key: 'roleType',
      label: '类型',
      width: '140',
      formatter: (row, col, val) => {
        return val === 1 ? '超级管理员' : '普通管理员'
      }
    },
    {
      key: 'remark',
      label: '说明',
      width: '140'
    },
    {
      key: 'roleStatus',
      label: '状态',
      width: '140',
      formatter: (row, col, val) => {
        return val === 1 ? '正常' : '禁用'
      }
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '140'
    },
    {
      key: 'action',
      label: '操作',
      width: '140',
      fixed: 'right',
      slot: 'action'
    }
  ]
  return {
    ...common,
    pagination,
    column
  }
}
